import React from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { useUserAccessContext } from '../../contexts/UserAccessContext';

const Sidebar = () => {
    const location = useLocation();
    const history = useHistory();
    const { userHasRAccess } = useUserAccessContext();

    const onLogoutUser = () => {
        history.replace('/user-pages/choice');
        localStorage.clear();
    };

    const isPathActive = (path) => {
        return location.pathname.startsWith(path);
    };

    const isChildPathActive = (path) => {
        return location.pathname === path;
    };

    return (
        <nav className="sidebar sidebar-offcanvas" id="sidebar">
            <ul className="nav">
                <li className="nav-item nav-category">
                    <Trans>Main</Trans>
                </li>
                <li className={isPathActive('/dashboard') ? 'nav-item active' : 'nav-item'}>
                    <Link className="nav-link" to="/dashboard">
                        <span className="icon-bg">
                            <i className="mdi mdi-cube menu-icon"></i>
                        </span>
                        <span className="menu-title">
                            <Trans>Appointments</Trans>
                        </span>
                    </Link>
                </li>
                {userHasRAccess('Staff List') && (
                    <li className={isPathActive('/view-staff') ? 'nav-item active' : 'nav-item'}>
                        <Link className="nav-link" to="/view-staff">
                            <span className="icon-bg">
                                <i className="mdi mdi-cube menu-icon"></i>
                            </span>
                            <span className="menu-title">
                                <Trans>Staff</Trans>
                            </span>
                        </Link>
                    </li>
                )}
                <li className={isPathActive('/view-patient') ? 'nav-item active' : 'nav-item'}>
                    <Link className="nav-link" to="/view-patient">
                        <span className="icon-bg">
                            <i className="mdi mdi-cube menu-icon"></i>
                        </span>
                        <span className="menu-title">
                            <Trans>Client</Trans>
                        </span>
                    </Link>
                </li>
                <li className={isPathActive('/notifications') ? 'nav-item active' : 'nav-item'}>
                    <Link className="nav-link" to="/notifications">
                        <span className="icon-bg">
                            <i className="mdi mdi-cube menu-icon"></i>
                        </span>
                        <span className="menu-title">
                            <Trans>Notifications</Trans>
                        </span>
                    </Link>
                </li>
                {/* <li className={isPathActive('/forms/form7') ? 'nav-item active' : 'nav-item'}>
                    <Link className="nav-link" to="/forms/form7">
                        <span className="icon-bg">
                            <i className="mdi mdi-cube menu-icon"></i>
                        </span>
                        <span className="menu-title">
                            <Trans>Availability</Trans>
                        </span>
                    </Link>
                </li>
                <li className={isPathActive('/forms/form6') ? 'nav-item active' : 'nav-item'}>
                    <Link className="nav-link" to="/forms/form6">
                        <span className="icon-bg">
                            <i className="mdi mdi-cube menu-icon"></i>
                        </span>
                        <span className="menu-title">
                            <Trans>Staff Training</Trans>
                        </span>
                    </Link>
                </li>
                <li className={isPathActive('/forms/form1/') ? 'nav-item active' : 'nav-item'}>
                    <Link className="nav-link" to="/forms/form1/view-lesson-types">
                        <span className="icon-bg">
                            <i className="mdi mdi-cube menu-icon"></i>
                        </span>
                        <span className="menu-title">
                            <Trans>TIPS</Trans>
                        </span>
                    </Link>
                </li>
                <li className={isPathActive('/forms/form2/') ? 'nav-item active' : 'nav-item'}>
                    <Link className="nav-link" to="/forms/form2/view-lesson-types">
                        <span className="icon-bg">
                            <i className="mdi mdi-cube menu-icon"></i>
                        </span>
                        <span className="menu-title">
                            <Trans>MOPS</Trans>
                        </span>
                    </Link>
                </li>
                <li className={isChildPathActive('/forms/form4') || isChildPathActive('/forms/form4/form42') ? 'nav-item active' : 'nav-item'}>
                    <div
                        className={isChildPathActive('/forms/form4') || isChildPathActive('/forms/form4/form42') ? 'nav-link menu-expanded' : 'nav-link'}
                        onClick={() => history.push('/forms/form4')}
                        // data-toggle='collapse'
                    >
                        <span className="icon-bg">
                            <i className="mdi mdi-cube menu-icon"></i>
                        </span>
                        <span className="menu-title">
                            <Trans>Caregiver</Trans>
                        </span>
                        <i className="menu-arrow"></i>
                    </div>
                    <Collapse
                        in={isChildPathActive('/forms/form4') || isChildPathActive('/forms/form4/form42') || isChildPathActive('/forms/form4/form43') || isChildPathActive('/forms/form4/form44')}>
                        <ul className="nav flex-column sub-menu">
                            <li className="nav-item">
                                <Link className={isChildPathActive('/forms/form4') ? 'nav-link active' : 'nav-link'} to="/forms/form4">
                                    <div className="width80">
                                        <Trans>Setting up Caregiver Training Tracker</Trans>
                                    </div>
                                </Link>
                            </li>
                            <li className="nav-item">
                                {' '}
                                <Link className={isPathActive('/forms/form4/form42') ? 'nav-link active' : 'nav-link'} to="/forms/form4/form42">
                                    <div className="width80">
                                        <Trans>Discussing caregiver priorities</Trans>
                                    </div>
                                </Link>
                            </li>
                            <li className="nav-item">
                                {' '}
                                <Link className={isChildPathActive('/forms/form4/form43') ? 'nav-link active' : 'nav-link'} to="/forms/form4/form43">
                                    <div className="width80">
                                        <Trans>Caregiver meeting notes</Trans>
                                    </div>
                                </Link>
                            </li>
                            <li className="nav-item">
                                {' '}
                                <Link className={isPathActive('/forms/form4/form44') ? 'nav-link active' : 'nav-link'} to="/forms/form4/form44">
                                    <div className="width80">
                                        <Trans>Caregiver training data</Trans>
                                    </div>
                                </Link>
                            </li>
                        </ul>
                    </Collapse>
                </li>

                <li className={isPathActive('/forms/form3') ? 'nav-item active' : 'nav-item'}>
                    <Link className="nav-link" to="/forms/form3">
                        <span className="icon-bg">
                            <i className="mdi mdi-cube menu-icon"></i>
                        </span>
                        <span className="menu-title">
                            <Trans>BSP</Trans>
                        </span>
                    </Link>
                </li> */}

                {/* <li className={isPathActive('/view-spreadsheet') ? 'nav-item active' : 'nav-item'}>
                    <Link className="nav-link" to="/view-spreadsheet">
                        <span className="icon-bg">
                            <i className="mdi mdi-cube menu-icon"></i>
                        </span>
                        <span className="menu-title">
                            <Trans>SpreadSheet</Trans>
                        </span>
                    </Link>
                </li> */}
                {/* <li className={isPathActive('/forms/form5') ? 'nav-item active' : 'nav-item'}>
                    <div
                        className={isPathActive('/forms/form5') ? 'nav-link menu-expanded' : 'nav-link'}
                        onClick={() => history.push('/forms/form5')}
                        // data-toggle='collapse1'
                    >
                        <span className="icon-bg">
                            <i className="mdi mdi-cube menu-icon"></i>
                        </span>
                        <span className="menu-title">
                            <Trans>Lesson Progress</Trans>
                        </span>
                        <i className="menu-arrow"></i>
                    </div>
                    <Collapse in={isPathActive('/forms/form5')}>
                        <ul className="nav flex-column sub-menu">
                            <li className="nav-item">
                                {' '}
                                <Link className={isChildPathActive('/forms/form5') ? 'nav-link active' : 'nav-link'} to="/forms/form5">
                                    <Trans>Lesson & Behavior</Trans>
                                </Link>
                            </li>
                            <li className="nav-item">
                                {' '}
                                <Link className={isChildPathActive('/forms/form5/form52') ? 'nav-link active' : 'nav-link'} to="/forms/form5/form52">
                                    <Trans>Behavior</Trans>
                                </Link>
                            </li>
                        </ul>
                    </Collapse>
                </li>
                <li className={isPathActive('/performance-indicators') ? 'nav-item active' : 'nav-item'}>
                    <Link className="nav-link" to="/performance-indicators">
                        <span className="icon-bg">
                            <i className="mdi mdi-cube menu-icon"></i>
                        </span>
                        <span className="menu-title">
                            <Trans>Performance Indicators</Trans>
                        </span>
                    </Link>
                </li>
                <li className={isPathActive('/notifications') ? 'nav-item active' : 'nav-item'}>
                    <Link className="nav-link" to="/notifications">
                        <span className="icon-bg">
                            <i className="mdi mdi-cube menu-icon"></i>
                        </span>
                        <span className="menu-title">
                            <Trans>Notifications</Trans>
                        </span>
                    </Link>
                </li>
                <li className={isPathActive('/policy') ? 'nav-item active' : 'nav-item'}>
                    <Link className="nav-link" to="/policy">
                        <span className="icon-bg">
                            <i className="mdi mdi-cube menu-icon"></i>
                        </span>
                        <span className="menu-title">
                            <Trans>Policy</Trans>
                        </span>
                    </Link>
                </li>
                <li className={isPathActive('/terms') ? 'nav-item active' : 'nav-item'}>
                    <Link className="nav-link" to="/terms">
                        <span className="icon-bg">
                            <i className="mdi mdi-cube menu-icon"></i>
                        </span>
                        <span className="menu-title">
                            <Trans>Terms & Conditions</Trans>
                        </span>
                    </Link>
                </li>
                <li className={isPathActive('/faq') ? 'nav-item active' : 'nav-item'}>
                    <Link className="nav-link" to="/faq">
                        <span className="icon-bg">
                            <i className="mdi mdi-cube menu-icon"></i>
                        </span>
                        <span className="menu-title">
                            <Trans>FAQs</Trans>
                        </span>
                    </Link>
                </li>
                <li className={isPathActive('/user-pages/admin/login') ? 'nav-item active' : 'nav-item'} onClick={() => onLogoutUser()}>
                    <div className="nav-link">
                        <span className="icon-bg">
                            <i className="mdi mdi-logout menu-icon"></i>
                        </span>
                        <span className="menu-title">
                            <Trans>Logout</Trans>
                        </span>
                    </div>
                </li> */}
            </ul>
        </nav>
    );
};

export default Sidebar;
