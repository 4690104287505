import React, { Component, Suspense, lazy, useState, useEffect, useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Spinner from './components/shared/Spinner';
import AppContext from './Context';

const Dashboard = lazy(() => import('./pages/dashboard/Dashboard'));

// const Buttons = lazy(() => import('./basic-ui/Buttons'));
// const Dropdowns = lazy(() => import('./basic-ui/Dropdowns'));
// const Typography = lazy(() => import('./basic-ui/Typography'));

// const BasicElements = lazy(() => import('./form-elements/BasicElements'));

// const BasicTable = lazy(() => import('./tables/BasicTable'));

// const Mdi = lazy(() => import('./icons/Mdi'));

// const ChartJs = lazy(() => import('./charts/ChartJs'));

const Error404 = lazy(() => import('./pages/error-pages/Error404'));
const Error500 = lazy(() => import('./pages/error-pages/Error500'));

const UserChoice = lazy(() => import('./pages/user-pages/index'));
const StaffLogin = lazy(() => import('./pages/user-pages/Staff/Login'));
const AdminLogin = lazy(() => import('./pages/user-pages/Admin/Login'));
const StaffResetPassword = lazy(() => import('./pages/user-pages/Staff/ResetPassword'));
const AdminResetPassword = lazy(() => import('./pages/user-pages/Admin/ResetPassword'));
const StaffResetPasswordSuccess = lazy(() => import('./pages/user-pages/Staff/ResetPasswordSuccess'));
const AdminResetPasswordSuccess = lazy(() => import('./pages/user-pages/Admin/ResetPasswordSuccess'));
const StaffResetCode = lazy(() => import('./pages/user-pages/Staff/ResetCode'));
const AdminResetCode = lazy(() => import('./pages/user-pages/Admin/ResetCode'));
const StaffNewPassword = lazy(() => import('./pages/user-pages/Staff/NewPassword'));
const AdminNewPassword = lazy(() => import('./pages/user-pages/Admin/NewPassword'));
const AddPatient = lazy(() => import('./pages/Patient/AddPatient'));
const EditClient = lazy(() => import('./pages/Patient/EditClient'));
const ViewPatient = lazy(() => import('./pages/Patient/ViewPatient'));
const ViewClientProfile = lazy(() => import('./pages/Patient/ViewClientProfile'));
const AuthTimeLineProgramManager = lazy(() => import('./pages/Patient/ClientProfile/AuthTimeline/AuthTimelineProgramManager'));
const AuthTimelineBCBASupervisor = lazy(() => import('./pages/Patient/ClientProfile/AuthTimeline/AuthTimelineBCBASupervisor'));
const AuthTimeLineSeniorTherapist = lazy(() => import('./pages/Patient/ClientProfile/AuthTimeline/AuthTimelineSeniorTherapist'));

const AddBehaviorSupportPlan = lazy(() => import('./pages/Patient/ClientProfile/AddBehaviorSupportPlan.js'));
const EditBehaviorSupportPlan = lazy(() => import('./pages/Patient/ClientProfile/EditBehaviorSupportPlan/EditBehaviorSupportPlan.js'));
const ListBehaviorSupportPlan = lazy(() => import('./pages/Patient/ClientProfile/ListBehaviorSupportPlan'));
const AddLesson = lazy(() => import('./pages/Patient/ClientProfile/Lessons/AddLesson.js'));
const AddMasterTarget = lazy(() => import('./pages/Patient/ClientProfile/Lessons/AddMasterTarget'));
const BehaviorReduction = lazy(() => import('./pages/Patient/ClientProfile/Lessons/BehaviorReduction'));
const ReportScoring = lazy(() => import('./pages/Patient/ClientProfile/ReportScoring'));
const MonthlyTrackingPM = lazy(() => import('./pages/Patient/ClientProfile/MonthlyTracking/MonthlyTrackingPM'));
const MonthlyTrackingPMAddData = lazy(() => import('./pages/Patient/ClientProfile/MonthlyTracking/MonthlyTrackingPMAddData'));
const MonthlyTrackingBCBAAddData = lazy(() => import('./pages/Patient/ClientProfile/MonthlyTracking/MonthlyTrackingBCBAAddData'));
const MonthlyTrackingSTAddData = lazy(() => import('./pages/Patient/ClientProfile/MonthlyTracking/MonthlyTrackingSTAddData'));
const MonthlyTrackingPMMMAddData = lazy(() => import('./pages/Patient/ClientProfile/MonthlyTracking/MonthlyTrackingPMMMAddData'));
const MonthlyTrackingPMCMAddData = lazy(() => import('./pages/Patient/ClientProfile/MonthlyTracking/MonthlyTrackingPMCMAddData'));

const ChangeClientSchedule = lazy(() => import('./pages/Patient/ChangeClientSchedule.js'));
const AddStaff = lazy(() => import('./pages/StaffMember/AddStaff'));
const EditStaff = lazy(() => import('./pages/StaffMember/EditStaff'));
const ViewStaff = lazy(() => import('./pages/StaffMember/ViewStaff'));
const ViewStaffProfile = lazy(() => import('./pages/StaffMember/ViewStaffProfile'));
const ViewTipsMops = lazy(() => import('./pages/StaffMember/ViewTipsMops'));
const ViewTraining = lazy(() => import('./pages/Training/ViewTraining'));
const ViewAppointment = lazy(() => import('./pages/Appointment/ViewAppointment'));
const ViewSpreadSheet = lazy(() => import('./pages/SpreadSheet/ViewSpreadSheet'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const PerformanceIndicators = lazy(() => import('./pages/PerformanceIndicators'));
const Notifications = lazy(() => import('./pages/Notifications'));
const TermsAndConditions = lazy(() => import('./pages/TermsAndConditions'));
const FrequentQuestions = lazy(() => import('./pages/FrequentQuestions'));

const AddNewTask = lazy(() => import('./pages/NewTask/AddNewTask'));
const AddNewTaskAdmin = lazy(() => import('./pages/NewTask/AddNewTaskAdmin'));
const AddDirectServices = lazy(() => import('./pages/NewTask/AddDirectServices'));
const AddTodoTask = lazy(() => import('./pages/NewTask/AddTodoTask'));
const AddStaffDirectServices = lazy(() => import('./pages/NewTask/AddStaffDirectServices'));
const AddStaffOther = lazy(() => import('./pages/NewTask/AddStaffOther'));
const AddStaffParentTraining = lazy(() => import('./pages/NewTask/AddStaffParentTraining'));
const AddStaffHireTraining = lazy(() => import('./pages/NewTask/AddStaffHireTraining'));
const AddStaffClientProgress = lazy(() => import('./pages/NewTask/AddStaffClientProgress'));
const AddStaffTraining = lazy(() => import('./pages/NewTask/AddStaffTraining'));
const AddStaffTipMop = lazy(() => import('./pages/NewTask/AddStaffTipMop'));
const AddTipsMops = lazy(() => import('./pages/Tasks/AddTipsMops'));
const AddTraining = lazy(() => import('./pages/Tasks/AddTraining'));
const AddNewHireTraining = lazy(() => import('./pages/Tasks/AddNewHireTraining'));
const AddParentTraining = lazy(() => import('./pages/Tasks/AddParentTraining'));
const DirectServices = lazy(() => import('./pages/Tasks/DirectServices'));
const TeamMeeting = lazy(() => import('./pages/Tasks/TeamMeeting'));
const OtherTask = lazy(() => import('./pages/Tasks/OtherTask'));
const TodoTask = lazy(() => import('./pages/Tasks/TodoTask'));
const Analytics = lazy(() => import('./pages/Patient/ClientProfile/Analytics/index'));
const Form1 = lazy(() => import('./pages/forms/form1/index.js'));
const ViewLesson1 = lazy(() => import('./pages/forms/form1/ViewLesson1'));
const ViewLessonType1 = lazy(() => import('./pages/forms/form1/ViewLessonType1'));
const ViewLessonList1 = lazy(() => import('./pages/forms/form1/ViewLessonList1'));

const Form2 = lazy(() => import('./pages/forms/form2'));
const ViewLesson2 = lazy(() => import('./pages/forms/form2/ViewLesson2'));
const ViewLessonType2 = lazy(() => import('./pages/forms/form2/ViewLessonType2'));
const ViewLessonList2 = lazy(() => import('./pages/forms/form2/ViewLessonList2'));

const FormList3 = lazy(() => import('./pages/forms/form3'));
const Form31 = lazy(() => import('./pages/forms/form3/form31'));
const Form31Update = lazy(() => import('./pages/forms/form3/form31Update'));
const Form32 = lazy(() => import('./pages/forms/form3/form32'));
const ViewList32 = lazy(() => import('./pages/forms/form3/ViewList32'));

const Form41 = lazy(() => import('./pages/forms/form4/form41'));
const CaregiverList = lazy(() => import('./pages/forms/form4/list'));
const ViewList41 = lazy(() => import('./pages/forms/form4/ViewList41'));
const Form42 = lazy(() => import('./pages/forms/form4/list'));
const ViewList42 = lazy(() => import('./pages/forms/form4/ViewList42'));
const Form43 = lazy(() => import('./pages/forms/form4/list43'));
const ViewList43 = lazy(() => import('./pages/forms/form4/ViewList43'));
const Form44 = lazy(() => import('./pages/forms/form4/form44'));

const LBList = lazy(() => import('./pages/forms/form5/list'));
const Form51 = lazy(() => import('./pages/forms/form5/form51'));
const Form52 = lazy(() => import('./pages/forms/form5/form52'));

const Form6 = lazy(() => import('./pages/forms/form6'));
const Form6showAll = lazy(() => import('./pages/forms/form6/showAll'));
const ViewList6 = lazy(() => import('./pages/forms/form6/ViewList'));

const Form7 = lazy(() => import('./pages/forms/form7'));

const FeedbackToStaff = lazy(() => import('./pages/Mops/FeedbackToStaff'));
const TrainingStaffFamily = lazy(() => import('./pages/Mops/TrainingStaffFamily'));

const Tips = lazy(() => import('./pages/Tips'));
const AddLessonPreparation = lazy(() => import('./pages/Tips/AddLessonPreparation'));
const LessonPreparationList = lazy(() => import('./pages/Tips/LessonPreparationList'));
const AddTaskAnalysis = lazy(() => import('./pages/Tips/AddTaskAnalysis'));
const AddNaturalEnvironmentTraining = lazy(() => import('./pages/Tips/AddNaturalEnvironmentTraining'));
const PerformanceIndicator = lazy(() => import('./pages/PerformanceIndicator'));
const MopsResults = lazy(() => import('./pages/MopsResults'));
const TipsResults = lazy(() => import('./pages/TipsResults'));
const MyProfile = lazy(() => import('./pages/MyProfile'));

//Staff Traing Routes
const StaffTrainingComprehensionForm = lazy(() => import('./pages/StaffTraining/StaffTrainingFirstPage'));
const StaffTrainingTieForm = lazy(() => import('./pages/StaffTraining/StaffTrainingTieForm'));
const StaffTrainingNewFormSteps = lazy(() => import('./pages/StaffTraining/StaffTrainingNewFormOne'));
const StaffTrainingNewFormTreatmentIntegrity = lazy(() => import('./pages/StaffTraining/StaffTrainingNewFormTwo'));
const StaffTrainingFeedback = lazy(() => import('./pages/StaffTraining/StaffTrainingFeedback'));
const StaffTrainingList = lazy(() => import('./pages/StaffTraining/StaffTrainingList'));

//Parent Training
const ParentTraining = lazy(() => import('./pages/ParentTraining/ParentTraining'));
const ParentTrainingSetGoal = lazy(() => import('./pages/ParentTraining/ParentTrainingSetGoal'));
const CaregiverTrainingNewFormOne = lazy(() => import('./pages/ParentTraining/CaregiverTrainingNewFormOne.js'));
const CaregiverTrainingNewFormTwo = lazy(() => import('./pages/ParentTraining/CaregiverTrainingNewFormTwo.js'));
const CaregiverTrainingTieForm = lazy(() => import('./pages/ParentTraining/CaregiverTrainingTieForm.js'));

function AppRoutes({}) {
    const { setUser } = useContext(AppContext);
    useEffect(() => {
        const userData = localStorage.getItem('user');
        if (userData) {
            setUser(JSON.parse(userData));
        }
    }, []);
    return (
        <Suspense fallback={<Spinner />}>
            <Switch>
                <Route exact path="/dashboard" component={Dashboard} />
                <Route path="/user-pages/choice" component={UserChoice} />
                <Route path="/user-pages/admin/login" component={AdminLogin} />
                <Route path="/user-pages/staff/login" component={StaffLogin} />
                <Route path="/user-pages/staff/reset-password" component={StaffResetPassword} />
                <Route path="/user-pages/admin/reset-password" component={AdminResetPassword} />
                <Route path="/user-pages/staff/reset-password-success" component={StaffResetPasswordSuccess} />
                <Route path="/user-pages/admin/reset-password-success" component={AdminResetPasswordSuccess} />
                <Route path="/user-pages/staff/reset-code" component={StaffResetCode} />
                <Route path="/user-pages/admin/reset-code" component={AdminResetCode} />
                <Route path="/user-pages/staff/new-password" component={StaffNewPassword} />
                <Route path="/user-pages/admin/new-password" component={AdminNewPassword} />
                <Route path="/add-patient" component={AddPatient} />
                <Route path="/edit-client/:id" component={EditClient} />
                <Route path="/view-patient" component={ViewPatient} />
                <Route path="/view-client-profile/:id" component={ViewClientProfile} />
                <Route path="/client-profile/auth-timeline-bs/:id" component={AuthTimelineBCBASupervisor} />
                <Route path="/client-profile/auth-timeline-pm/:id" component={AuthTimeLineProgramManager} />
                <Route path="/client-profile/auth-timeline-st/:id" component={AuthTimeLineSeniorTherapist} />

                <Route path="/client-profile/add-new-behavior-support-plan/:id" component={AddBehaviorSupportPlan} />
                <Route path="/client-profile/list-behavior-support-plan/:id" component={ListBehaviorSupportPlan} />
                <Route path="/client-profile/edit-behavior-support-plan/:id" component={EditBehaviorSupportPlan} />
                <Route path="/client-profile/view-behavior-support-plan/:id" component={EditBehaviorSupportPlan} />
                <Route path="/client-profile/update-lessons/:id" component={AddLesson} />
                <Route path="/client-profile/update-mastered-target/:id" component={AddMasterTarget} />
                <Route path="/client-profile/view-lessons/:id" component={AddLesson} />
                <Route path="/client-profile/view-mastered-target/:id" component={AddMasterTarget} />
                <Route path="/client-profile/behavior-reduction/:id" component={BehaviorReduction} />
                <Route path="/client-profile/view-behavior-reduction/:id" component={BehaviorReduction} />
                <Route path="/client-profile/report-scoring/:id" component={ReportScoring} />
                <Route path="/client-profile/view-report-scoring/:id" component={ReportScoring} />
                <Route path="/client-profile/monthly-tracking-program-manager/:id" component={MonthlyTrackingPM} />
                <Route path="/client-profile/monthly-tracking-program-manager-addData/:id" component={MonthlyTrackingPMAddData} />
                <Route path="/client-profile/monthly-tracking-bcba-supervisor-addData/:id" component={MonthlyTrackingBCBAAddData} />
                <Route path="/client-profile/monthly-tracking-senior-therapist-addData/:id" component={MonthlyTrackingSTAddData} />
                <Route path="/client-profile/monthly-tracking-pm-mm-addData/:id" component={MonthlyTrackingPMMMAddData} />
                <Route path="/client-profile/monthly-tracking-pm-cm-addData/:id" component={MonthlyTrackingPMCMAddData} />
                <Route path="/change-client-schedule/:id" component={ChangeClientSchedule} />
                <Route path="/add-staff" component={AddStaff} />
                <Route path="/edit-staff/:id" component={EditStaff} />
                <Route path="/view-staff" component={ViewStaff} />
                <Route path="/view-staff-profile/:id" component={ViewStaffProfile} />
                <Route path="/view-tips/:id" component={ViewTipsMops} />
                <Route path="/view-mops/:id" component={ViewTipsMops} />
                <Route path="/view-appointment" component={ViewAppointment} />
                <Route path="/view-training" component={ViewTraining} />
                <Route path="/view-spreadsheet" component={ViewSpreadSheet} />
                <Route path="/performance-indicators" component={PerformanceIndicators} />
                <Route path="/notifications" component={Notifications} />
                <Route path="/policy" component={PrivacyPolicy} />
                <Route path="/terms" component={TermsAndConditions} />
                <Route path="/faq" component={FrequentQuestions} />
                <Route exact path="/add-new-task" component={AddNewTask} />
                <Route exact path="/add-admin-new-task" component={AddNewTaskAdmin} />
                {/* <Route exact path="/add-direct-service/:id" component={AddDirectServices} />
                <Route exact path="/add-direct-service" component={AddDirectServices} /> */}
                <Route exact path="/add-todo/:id" component={AddTodoTask} />
                <Route exact path="/add-todo" component={AddTodoTask} />
                <Route exact path="/add-staff-direct-service/:id" component={AddStaffDirectServices} />
                <Route exact path="/add-staff-direct-service" component={AddStaffDirectServices} />
                <Route exact path="/add-staff-other/:id" component={AddStaffOther} />
                <Route exact path="/add-staff-other" component={AddStaffOther} />
                <Route exact path="/add-staff-parent-training/:id" component={AddStaffParentTraining} />
                <Route exact path="/add-staff-parent-training" component={AddStaffParentTraining} />
                <Route exact path="/add-staff-new-hire/:id" component={AddStaffHireTraining} />
                <Route exact path="/add-staff-new-hire" component={AddStaffHireTraining} />
                <Route exact path="/add-staff-client-progress/:id" component={AddStaffClientProgress} />
                <Route exact path="/add-staff-client-progress" component={AddStaffClientProgress} />
                <Route exact path="/add-staff-tips-mops/:id" component={AddStaffTipMop} />
                /** Staff Training Routes */
                <Route exact path="/staff-training-comprehension-form/:id" component={StaffTrainingComprehensionForm} />
                <Route exact path="/staff-training-tie-form/:id" component={StaffTrainingTieForm} />
                <Route exact path="/staff-training-new-form-steps/:id" component={StaffTrainingNewFormSteps} />
                <Route exact path="/staff-training-new-form-treatment-integrity/:id" component={StaffTrainingNewFormTreatmentIntegrity} />
                <Route exact path="/staff-training-feedback/:id" component={StaffTrainingFeedback} />
                <Route exact path="/staff-training-list/:id" component={StaffTrainingList} />
                /** Staff Training Routes */ /** Parent Training */
                <Route exact path="/parent-training/:id" component={ParentTraining} />
                <Route exact path="/parent-training-goal/:id" component={ParentTrainingSetGoal} />
                <Route exact path="/parent-training-tie-form/:id" component={CaregiverTrainingTieForm} />
                <Route exact path="/parent-training-new-form-steps/:id" component={CaregiverTrainingNewFormOne} />
                <Route exact path="/parent-training-new-form-treatment-integrity/:id" component={CaregiverTrainingNewFormTwo} />
                {/* <Route exact path="/add-staff-tips-mops" component={AddStaffTipMop} /> */}
                <Route exact path="/add-tips-mops" component={AddTipsMops} />
                <Route exact path="/add-feedback-to-staff/:id" component={FeedbackToStaff} />
                <Route exact path="/add-feedback-training-staff-family/:id" component={TrainingStaffFamily} />
                <Route exact path="/add-staff-training/:id" component={AddStaffTraining} />
                {/* <Route exact path="/add-staff-training" component={AddStaffTraining} /> */}
                <Route exact path="/add-training" component={AddTraining} />
                <Route exact path="/add-new-hire-training" component={AddNewHireTraining} />
                <Route exact path="/add-caregiver-training" component={AddParentTraining} />
                <Route exact path="/team-meeting" component={TeamMeeting} />
                <Route exact path="/add-direct-service" component={DirectServices} />
                <Route exact path="/add-other-task" component={OtherTask} />
                <Route exact path="/add-todo-task" component={TodoTask} />
                <Route exact path="/analytics/:id" component={Analytics} />
                <Route exact path="/forms/form1/view-lesson" component={ViewLesson1} />
                <Route exact path="/forms/form1/view-lesson/:id" component={ViewLesson1} />
                <Route exact path="/forms/form1/view-lesson/:id/:type" component={ViewLessonList1} />
                <Route exact path="/forms/form1/view-lesson-types" component={ViewLessonType1} />
                <Route exact path="/forms/form1/view-lesson-list" component={ViewLessonList1} />
                <Route exact path="/forms/form1/:id" component={Form1} />
                <Route exact path="/forms/form2/view-lesson" component={ViewLesson2} />
                <Route exact path="/forms/form2/view-lesson/:id" component={ViewLesson2} />
                <Route exact path="/forms/form2/view-lesson/:id/:type" component={ViewLessonList2} />
                <Route exact path="/forms/form2/view-lesson-types" component={ViewLessonType2} />
                <Route exact path="/forms/form2/view-lesson-list" component={ViewLessonList2} />
                <Route exact path="/forms/form2/:id" component={Form2} />
                <Route exact path="/forms/form3" component={FormList3} />
                <Route exact path="/forms/form3/form31/:id" component={Form31Update} />
                <Route exact path="/forms/form3/form31" component={Form31} />
                <Route exact path="/forms/form3/form32" component={Form32} />
                <Route exact path="/forms/form3/form32/:id" component={ViewList32} />
                <Route exact path="/forms/form4" component={CaregiverList} />
                <Route exact path="/forms/form4/form41" component={Form41} />
                <Route exact path="/forms/form4/form41/view-list" component={ViewList41} />
                <Route exact path="/forms/form4/form41/view-list/:id" component={ViewList41} />
                <Route exact path="/forms/form4/form42" component={Form42} />
                <Route exact path="/forms/form4/form42/view-list/:id" component={ViewList42} />
                <Route exact path="/forms/form4/form43" component={Form43} />
                <Route exact path="/forms/form4/form43/view-list/:id" component={ViewList43} />
                <Route exact path="/forms/form4/form44" component={Form44} />
                <Route exact path="/forms/form5" component={LBList} />
                <Route exact path="/forms/form5/form51" component={Form51} />
                <Route exact path="/forms/form5/form51/:id" component={Form51} />
                <Route exact path="/forms/form5/form52" component={Form52} />
                <Route exact path="/forms/form6" component={Form6showAll} />
                <Route exact path="/forms/form6/add" component={Form6} />
                <Route exact path="/forms/form6/:id" component={ViewList6} />
                <Route exact path="/forms/form7" component={Form7} />
                <Route path="/error-pages/error-404" component={Error404} />
                <Route path="/error-pages/error-500" component={Error500} />
                /** Tips Routes */
                <Route exact path="/add-discrete-lession/:id" component={Tips} />
                <Route exact path="/add-lession-preparation/:id" component={AddLessonPreparation} />
                <Route exact path="/add-lession-preparation-list/:id" component={LessonPreparationList} />
                <Route exact path="/add-task-analysis/:id" component={AddTaskAnalysis} />
                <Route exact path="/add-natural-environment-training/:id" component={AddNaturalEnvironmentTraining} />
                /** Tips Routes */
                <Route exact path="/performance-indicator/:id" component={PerformanceIndicator} />
                <Route exact path="/staff-feedback-mops-results" component={MopsResults} />
                <Route exact path="/staff-feedback-tips-results" component={TipsResults} />
                <Route path="/my-profile/:id" component={MyProfile} />
                <Redirect to="/dashboard" />
            </Switch>
        </Suspense>
    );
}

export default AppRoutes;
