import Axios from 'axios';

// const API_URL = `https://pna-services-app-28287-staging.botics.co/api/v1`;
const API_URL = window.location.hostname === 'localhost' 
|| window.location.hostname === 'pna-services-app-28287-staging.botics.co'
  ? `https://pna-services-app-28287-staging.botics.co/api/v1`
  : `${process.env.REACT_APP_BASE_URL}`; // your app back-end url
console.log("BASE_URL", API_URL)

export const HttpRequest = async (method, uri, data, headers = null, params = null) => {
    const url = `${API_URL}${uri}`;
    const configuration = {
        method: method,
        url: url
    };
    if (headers !== null) {
        configuration.headers = headers;
    }
    if (params !== null) {
        configuration.params = params;
    }
    if (method === 'post' || method === 'put' || method === 'delete' || method === 'patch') {
        configuration.data = data;
    }
    console.log(configuration, 'axios configuration==========');
    // return await apiCall(uri, configuration);
    return await Axios(configuration);
};

// export const apiCall = async (path, options) => {
//     return axios(options)
//         .then((response) => {
//             return response.data;
//         })
//         .catch(async (error) => {
//             return error.response;
//         });
// };

export const formatHeader = (header) => {
    let token = localStorage.getItem('token') || null;
    console.log('header in format header==', header)
    let headerObj={
        'Authorization': `Token ${token}`,
    }
    if (header && header['Content-Type'] !== undefined) {
        headerObj['Content-Type'] = header['Content-Type'];
    } 
    return headerObj;
};
