export const secondaryNavs = [
    {
        path: '/add-staff',
        title: 'Add a New Staff Member',
        subTitle: 'Back'
    },
    {
        path: '/add-patient',
        title: 'Add a New Client',
        subTitle: 'Back'
    },
    {
        path: '/add-admin-new-task',
        title: 'Add A New Task',
        subTitle: 'Back'
    },
    {
        path: '/view-staff-profile',
        title: 'Staff Profile',
        subTitle: 'Back'
    },
    {
        path: '/view-tips',
        title: 'TIPS',
        subTitle: 'Back'
    },
    {
        path: '/view-mops',
        title: 'MOPS',
        subTitle: 'Back'
    },
    {
        path: '/view-client-profile',
        title: 'Client Profile',
        subTitle: 'Back'
    },
    {
        path: '/analytics',
        title: 'ANALYTICS',
        subTitle: 'Back'
    },
    {
        path: '/client-profile/auth-timeline',
        title: 'Authorization Timeline',
        subTitle: 'Back'
    },
    {
        path: '/client-profile/add-new-behavior-support-plan',
        title: 'Behavior Support Plan',
        subTitle: 'Back'
    },
    {
        path: '/client-profile/edit-behavior-support-plan',
        title: 'Behavior Support Plan',
        subTitle: 'Back'
    },
    {
        path: '/client-profile/view-behavior-support-plan',
        title: 'Behavior Support Plan',
        subTitle: 'Back'
    },
    {
        path: '/client-profile/list-behavior-support-plan',
        title: 'Behavior Support Plan',
        subTitle: 'Back'
    },
    {
        path: '/client-profile/update-lessons',
        title: 'Lessons',
        subTitle: 'Back'
    },
    {
        path: '/client-profile/view-lessons',
        title: 'Lessons',
        subTitle: 'Back'
    },
    {
        path: '/client-profile/behavior-reduction',
        title: 'Behavior Reduction',
        subTitle: 'Back'
    },
    {
        path: '/client-profile/view-behavior-reduction',
        title: 'Behavior Reduction',
        subTitle: 'Back'
    },
    {
        path: '/client-profile/view-mastered-target',
        title: 'Mastered Targets',
        subTitle: 'Back'
    },
    {
        path: '/client-profile/update-mastered-target',
        title: 'Mastered Targets',
        subTitle: 'Back'
    },
    {
        path: '/client-profile/report-scoring',
        title: 'Report Scoring',
        subTitle: 'Back'
    },
    {
        path: '/client-profile/view-report-scoring',
        title: 'Report Scoring',
        subTitle: 'Back'
    },
    {
        path: '/client-profile/monthly-tracking-program-manager',
        title: 'Monthly Tracking For Program Manager',
        subTitle: 'Back'
    },
    {
        path: '/staff-training-comprehension-form',
        title: 'Staff Training',
        subTitle: 'Back'
    },
    {
        path: '/staff-training-tie-form',
        title: 'TIE',
        subTitle: 'Back'
    },
    {
        path: '/staff-training-new-form-steps',
        title: 'NEW',
        subTitle: 'Back'
    },
    {
        path: '/staff-training-new-form-treatment-integrity',
        title: 'NEW',
        subTitle: 'Back'
    },
    {
        path: '/staff-training-feedback',
        title: 'Staff Training',
        subTitle: 'Back'
    },
    {
        path: '/staff-training-list',
        title: 'View Staff Training',
        subTitle: 'Back'
    },
    {
        path: '/parent-training',
        title: 'Caregiver Training',
        subTitle: 'Back'
    },

    {
        path: '/edit-staff',
        title: 'Edit Staff',
        subTitle: 'Back'
    },
    {
        path: '/edit-client',
        title: 'Edit Client',
        subTitle: 'Back'
    },
    {
        path: '/add-tips-mops',
        title: 'Add TIPS/MOPS',
        subTitle: 'Back'
    },
    {
        path: '/add-training',
        title: 'Add Staff Training',
        subTitle: 'Back'
    },
    {
        path: '/add-new-hire-training',
        title: 'Add New Hire Training',
        subTitle: 'Back'
    },
    {
        path: '/add-caregiver-training',
        title: 'Add Caregiver Training',
        subTitle: 'Back'
    },
    {
        path: '/team-meeting',
        title: 'Team Meeting Screen',
        subTitle: 'Back'
    },
    {
        path: '/add-direct-service',
        title: 'Direct Services',
        subTitle: 'Back'
    },
    {
        path: '/add-other-task',
        title: 'Other Task',
        subTitle: 'Back'
    },
    {
        path: '/add-todo-task',
        title: 'To-Do',
        subTitle: 'Back'
    },
    {
        path: '/add-feedback-to-staff',
        title: '  MOPS',
        subTitle: 'Back'
    },
    {
        path: '/add-feedback-training-staff-family',
        title: '  MOPS',
        subTitle: 'Back'
    },
    {
        path: '/change-client-schedule',
        title: '  Change Client Schedule',
        subTitle: 'Back'
    },
    {
        path: '/add-discrete-lession/',
        title: 'TIPS',
        subTitle: 'Back'
    },
    {
        path: '/add-lession-preparation/',
        title: 'TIPS',
        subTitle: 'Back'
    },
    {
        path: '/add-lession-preparation-list/',
        title: 'TIPS',
        subTitle: 'Back'
    },
    {
        path: '/add-task-analysis/',
        title: 'TIPS',
        subTitle: 'Back'
    },
    {
        path: '/add-natural-environment-training/',
        title: 'TIPS',
        subTitle: 'Back'
    },
    {
        path: '/performance-indicator/',
        title: 'Performance Indicator',
        subTitle: 'Back'
    },
    {
        path: '/staff-feedback-mops-results',
        title: 'MOPS Results',
        subTitle: 'Back'
    },
    {
        path: '/staff-feedback-tips-results',
        title: 'TIPS Results',
        subTitle: 'Back'
    },
    {
        path: '/my-profile',
        title: 'My Profile',
        subTitle: 'Back'
    },
    {
        path: '/monthly-tracking-pm-mm-addData',
        title: 'Monthly Tracking for Program Manager',
        subTitle: 'Back'
    },
    {
        path: '/monthly-tracking-pm-cm-addData',
        title: 'Monthly Tracking for Program Manager',
        subTitle: 'Back'
    },
    {
        path: '/monthly-tracking-bcba-supervisor-addData',
        title: 'Monthly Tracking for BCBA Supervisor',
        subTitle: 'Back'
    },
    {
        path: '/monthly-tracking-senior-therapist-addData',
        title: 'Monthly Tracking for Senior Therapist',
        subTitle: 'Back'
    },
    {
        path: '/notifications',
        title: 'Notifications',
        subTitle: 'Back'
    }
];
