import React, { createContext, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import {
    addAuthTimeline,
    addSystemTask,
    deleteActivityTask,
    deleteOfflineTask,
    deleteSystemTask,
    duplicateActivityTask,
    duplicateOfflineTask,
    duplicateSystemTask,
    getAuthAssessment,
    getAuthorizationTimeline
} from '../services/patientService';
import moment from 'moment';

const AuthTimelineContext = createContext();

const AuthTimelineProvider = ({ children }) => {
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState({ activity_task: [], offline_task: [], system_task: [] });
    const [activityTask, setActivityTask] = useState([]);
    const [offlineTask, setOfflineTask] = useState([]);
    const [systemTask, setSystemTask] = useState([]);
    const [path, setPath] = useState(null);
    const [authAssessmentData, setAuthAssessmentData] = useState([]);

    const notifySuccess = (successMessage) => toast.success(successMessage,{
        className:'react-toast-message',
    });
    const notifyError = (errorMessage) => toast.error(errorMessage);

    const getAuthTimeline = async (config) => {
        setLoader(true);
        try {
            const response = await getAuthorizationTimeline(config);
            const { activity_task = [], offline_task = [], system_task = [] } = response.data;
            setActivityTask([...activity_task]);
            setOfflineTask([...offline_task]);
            setSystemTask([...system_task]);
            setData(response.data);
            setLoader(false);
        } catch (error) {
            console.log(error.response);
            setActivityTask([]);
            setOfflineTask([]);
            setSystemTask([]);
        } finally {
            setLoader(false);
        }
    };

    const createAuthTimeline = (payload) => {
        return new Promise((resolve, reject) => {
            addAuthTimeline(payload)
                .then(resolve('success'))
                .catch((error) => reject(error));
        });
    };

    const addTheSystemTask = (payload) => {
        return new Promise((resolve, reject) => {
            addSystemTask(payload)
                .then(resolve('success'))
                .catch((error) => reject(error));
        });
    };

    const duplicateTheSystemTask = (taskId) => {
        return new Promise((resolve, reject) => {
            const payload = {
                month: '',
                name: '',
                month_name: '',
                is_duplicate: true,
                note: '',
                task_type: '',
                created_for_month: moment(new Date()).format('YYYY-MM-DD'),
                atl: null
            };
            const { month, name, month_name, task_type, atl, note } = data.system_task?.find(({ id }) => id === taskId);
            payload.month = month;
            payload.name = name;
            payload.month_name = month_name;
            payload.note = note;
            payload.task_type = task_type;
            payload.atl = atl;
            setLoader(true);
            duplicateSystemTask(payload)
                .then(() => resolve('Success'))
                .catch((err) => {
                    reject(err);
                });
        });
    };

    const deleteTheSystemTask = (taskId) => {
        return new Promise((resolve, reject) => {
            deleteSystemTask(taskId)
                .then(() => resolve('Success'))
                .catch((err) => {
                    reject(err);
                });
        });
    };

    const duplicateTheActivityTask = (taskId) => {
        return new Promise((resolve, reject) => {
            const payload = {
                is_duplicate: true,
                month: '',
                name: '',
                month_name: '',
                due_date: '',
                completed_date: null,
                note: null,
                created_for_month: moment(new Date()).format('YYYY-MM-DD'),
                atl: null
            };
            const { month, name, month_name, due_date, atl, note, task_type } = data.activity_task?.find(({ id }) => id === taskId);
            payload.month = month;
            payload.name = name;
            payload.month_name = month_name;
            payload.note = note;
            payload.due_date = due_date;
            payload.atl = atl;
            payload.task_type = task_type
            setLoader(true);
            duplicateActivityTask(payload)
                .then(() => resolve('Success'))
                .catch((err) => reject(err));
        });
    };

    const deleteTheActivityTask = (taskId) => {
        return new Promise((resolve, reject) => {
            deleteActivityTask(taskId)
                .then(() => resolve('Success'))
                .catch((err) => reject(err));
        });
    };

    const duplicateTheOfflineTask = (taskId) => {
        return new Promise((resolve, reject) => {
            const payload = {
                is_duplicate: true,
                month: '',
                name: '',
                month_name: '',
                due_date: '',
                completed_date: null,
                note: '',
                created_for_month: moment(new Date()).format('YYYY-MM-DD'),
                atl: null
            };
            const { month, name, month_name, due_date, atl, note } = data.offline_task?.find(({ id }) => id === taskId);
            payload.month = month;
            payload.name = name;
            payload.month_name = month_name;
            payload.note = note;
            payload.due_date = due_date;
            payload.atl = atl;
            setLoader(true);
            duplicateOfflineTask(payload)
                .then(() => resolve('Success'))
                .catch((err) => reject(err));
        });
    };

    const deleteTheOfflineTask = (taskId) => {
        return new Promise((resolve, reject) => {
            deleteOfflineTask(taskId)
                .then(() => resolve('Success'))
                .catch((err) => reject(err));
        });
    };

    const updateOfflineTask = (value, index, name) => {
        const update = [...offlineTask];
        update[index][name] = value;
        setOfflineTask(update);
    };

    const updateActivityTask = (value, index, name) => {
        const update = [...activityTask];
        update[index][name] = value;
        setActivityTask(update);
    };

    const updateSystemTask = (value, index, name) => {
        const update = [...systemTask];
        update[index][name] = value;
        setSystemTask(update);
    };

    const getAuthAssessmentData = (config) => {
        return new Promise((resolve, reject) => {
            getAuthAssessment(config)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    };

    const updateAssessmentDate = (value, index, name) => {
        const update = [...authAssessmentData];
        update[index][name] = value;
        setAuthAssessmentData(update);
    };

    return (
        <AuthTimelineContext.Provider
            value={{
                loader,
                data,
                activityTask,
                offlineTask,
                systemTask,
                updateOfflineTask,
                getAuthTimeline,
                createAuthTimeline,
                notifySuccess,
                notifyError,
                addTheSystemTask,
                duplicateTheSystemTask,
                deleteTheSystemTask,
                duplicateTheActivityTask,
                deleteTheActivityTask,
                duplicateTheOfflineTask,
                deleteTheOfflineTask,
                updateActivityTask,
                updateSystemTask,
                path,
                setPath,
                authAssessmentData,
                getAuthAssessmentData,
                updateAssessmentDate
            }}>
            {children}
        </AuthTimelineContext.Provider>
    );
};

export const useAuthTimelineContext = () => useContext(AuthTimelineContext);

export default AuthTimelineProvider;
