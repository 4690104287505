import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import Navbar from './components/shared/Navbar';
import Sidebar from './components/shared/Sidebar';
import SettingsPanel from './components/shared/SettingsPanel';
import Footer from './components/shared/Footer';
import { withTranslation } from 'react-i18next';
import AppContext from './Context';
// import { getToken } from 'firebase/messaging';
import { onMessageListener, requestForToken } from './utils/firebase';
import Notification from './Notification';
import UserAccessProvider from './contexts/UserAccessContext';
import { ErrorAlert } from './components/ErrorAlert';
import PerformanceIndicatorProvider from './contexts/PerformanceIndicatorContext';
import AuthTimelineProvider from './contexts/AuthTimelineContext';
import './App.scss';

class App extends Component {
    state = {
        user: null,
        token: false
    };

    componentDidMount() {
        this.onRouteChanged();
        let token = localStorage.getItem('token');
        if (token) {
            // this.props.history.push('/dashboard');
        } else {
            this.props.history.push('/user-pages/choice');
        }
    }
    setUser = (user) => {
        this.setState({ user });
    };

    setToken = (token) => {
        this.setState({ token });
    };

    render() {
        const { user } = this.state;
        let navbarComponent = !this.state.isFullPageLayout ? <Navbar /> : '';
        let sidebarComponent = !this.state.isFullPageLayout ? <Sidebar /> : '';
        let SettingsPanelComponent = !this.state.isFullPageLayout ? <SettingsPanel /> : '';
        let footerComponent = !this.state.isFullPageLayout ? <Footer /> : '';
        return (
            <AppContext.Provider
                value={{
                    user,
                    setUser: this.setUser
                }}>
                <Notification />
                <UserAccessProvider>
                    <PerformanceIndicatorProvider>
                        <AuthTimelineProvider>
                            <div className="container-scroller">
                                {navbarComponent}
                                <div className="container-fluid page-body-wrapper">
                                    {sidebarComponent}
                                    <div className="main-panel">
                                        <div className="content-wrapper">
                                            <AppRoutes />

                                            {SettingsPanelComponent}
                                        </div>
                                        {footerComponent}
                                    </div>
                                </div>
                            </div>
                            <ErrorAlert />
                        </AuthTimelineProvider>
                    </PerformanceIndicatorProvider>
                </UserAccessProvider>
            </AppContext.Provider>
        );
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
    }

    onRouteChanged() {
        const { i18n } = this.props;
        const body = document.querySelector('body');
        if (this.props.location.pathname === '/layout/RtlLayout') {
            body.classList.add('rtl');
            i18n.changeLanguage('ar');
        } else {
            body.classList.remove('rtl');
            i18n.changeLanguage('en');
        }
        window.scrollTo(0, 0);
        const fullPageLayoutRoutes = [
            '/user-pages/choice',
            '/user-pages/staff/login',
            '/user-pages/admin/login',
            '/user-pages/staff/register',
            '/user-pages/admin/register',
            '/user-pages/staff/reset-password',
            '/user-pages/admin/reset-password',
            '/user-pages/staff/reset-password-success',
            '/user-pages/admin/reset-password-success',
            '/user-pages/staff/reset-code',
            '/user-pages/admin/reset-code',
            '/user-pages/admin/new-password',
            '/user-pages/staff/new-password',
            '/user-pages/admin/admin/reset-code',
            '/user-pages/lockscreen',
            '/error-pages/error-404',
            '/error-pages/error-500',
            '/general-pages/landing-page'
        ];
        for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
            if (this.props.location.pathname === fullPageLayoutRoutes[i]) {
                this.setState({
                    isFullPageLayout: true
                });
                document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
                break;
            } else {
                this.setState({
                    isFullPageLayout: false
                });
                document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
            }
        }
    }
}

export default withTranslation()(withRouter(App));
