import React, { createContext, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { getSpecificPatient } from '../services/patientService';
const PerformanceIndicatorContext = createContext();

const PerformanceIndicatorProvider = ({ children }) => {
    const [key, setKey] = useState('MOPS');
    const [loader, setLoader] = useState(false);
    const [MTData, setMTData] = useState({ monthly_tracking_bcba: [], monthly_tracking_senior_therapist: [] });
    const [clientInfo, setClientInfo] = useState(null);

    const notifySuccess = (successMessage) => toast.success(successMessage);
    const notifyError = (errorMessage) => toast.error(errorMessage);

    const getClientInfo = async (id) => {
        setLoader(true);
        try {
            const response = await getSpecificPatient(id);
            const clientDetails = response.data;
            setClientInfo(clientDetails);
            setLoader(false);
        } catch (error) {
            console.log(error.response);
        } finally {
            setLoader(false);
        }
    };

    return (
        <PerformanceIndicatorContext.Provider
            value={{
                key,
                setKey,
                MTData,
                setMTData,
                clientInfo,
                getClientInfo,
                notifySuccess,
                notifyError
            }}>
            {children}
        </PerformanceIndicatorContext.Provider>
    );
};

export const usePerformanceIndicatorContext = () => useContext(PerformanceIndicatorContext);

export default PerformanceIndicatorProvider;
