import { HttpRequest, formatHeader } from '../utils/httpRequest';

export const getAllPatient = (config) => HttpRequest('get', '/client_list/', null, formatHeader(), config);

export const getAllPaginatedPatient = (config) => HttpRequest('get', '/clients/', null, formatHeader(), config);

export const getSpecificPatient = (id) => HttpRequest('get', `/clients/${id}/`, null, formatHeader());

export const createPatient = (data) => HttpRequest('post', '/clients/', data, formatHeader());

export const deleteSpecificPatient = (id) => HttpRequest('delete', `/clients/${id}/`, null, formatHeader());

export const editPatient = (id, data) => HttpRequest('patch', `/clients/${id}/`, data, formatHeader());

export const addClientSchedule = (data) => HttpRequest('post', '/client-schedule/', data, formatHeader());

export const getClientSchedule = (id) => HttpRequest('get', `/client-schedule/${id}/`, null, formatHeader());

export const updateClientSchedule = (id, data) => HttpRequest('patch', `/client-schedule/${id}/`, data, formatHeader());

export const getMonthlyTracking = (config) => HttpRequest('get', `/monthlytracking/`, null, formatHeader(), config);

export const addMTBCBAPercentage = (data) => HttpRequest('post', '/monthlytrackingforbcbapercentage/', data, formatHeader());

export const addMTBCBA = (data) => HttpRequest('post', '/monthlytrackingforbcba/', data, formatHeader());

export const addMTSTPercentage = (data) => HttpRequest('post', '/monthlytrackingforseniortherapistpercentage/', data, formatHeader());

export const addMTST = (data) => HttpRequest('post', '/monthlytrackingforseniortherapist/', data, formatHeader());

export const addMTPMMMPercentage = (data) => HttpRequest('post', '/monthlytrackingprogrammanagerpercentage/', data, formatHeader());

export const addMTPMMM = (data) => HttpRequest('post', '/monthlytrackingprogrammanager/', data, formatHeader());

export const addMTPMCMPercentage = (data) => HttpRequest('post', '/consultativemodelpercentage/', data, formatHeader());

export const addMTPMCM = (data) => HttpRequest('post', '/consultativemodel/', data, formatHeader());

export const getAuthorizationTimeline = (config) => HttpRequest('get', `/authorisation_time_line/`, null, formatHeader(), config);

export const duplicateSystemTask = (data) => HttpRequest('post', '/system_task/', data, formatHeader());

export const addSystemTask = (data) => HttpRequest('post', '/system_task/add_task/', data, formatHeader());

export const deleteSystemTask = (id) => HttpRequest('delete', `/system_task/${id}/`, null, formatHeader());

export const duplicateActivityTask = (data) => HttpRequest('post', '/activity_task/', data, formatHeader());

export const deleteActivityTask = (id) => HttpRequest('delete', `/activity_task/${id}/`, null, formatHeader());
export const updateActivityTask = (id, data) => HttpRequest('patch', `/activity_task/${id}/`, data, formatHeader());

export const duplicateOfflineTask = (data) => HttpRequest('post', '/offline_task/', data, formatHeader());

export const deleteOfflineTask = (id) => HttpRequest('delete', `/offline_task/${id}/`, null, formatHeader());
export const updateOfflineTask = (id, data) => HttpRequest('patch', `/offline_task/${id}/`, data, formatHeader());
export const addAuthTimeline = (data) => HttpRequest('post', '/authorisation_time_line/', data, formatHeader());

export const getAuthAssessment = (config) => HttpRequest('get', `/authorization_assessment/`, null, formatHeader(), config);

export const duplicateAuthAssessment = (data) => HttpRequest('post', '/authorization_assessment/', data, formatHeader());

export const deleteAuthAssessment = (id) => HttpRequest('delete', `/authorization_assessment/${id}/`, null, formatHeader());

export const addAuthAssessment = (id, data) => HttpRequest('put', `/authorization_assessment/${id}/`, data, formatHeader());

export const getOfflineTasks = (config) => HttpRequest('get', '/offline_task/', null, formatHeader(), config);

export const getActivityTasks = (config) => HttpRequest('get', '/activity_task/', null, formatHeader(), config);