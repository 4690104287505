import { HttpRequest, formatHeader } from '../utils/httpRequest';

export const loginUser = (data) => HttpRequest('post', '/login/', data, null);

export const changePassword = (data) => HttpRequest('post', '/rest-auth/password/change', data, null);

export const resetPassword = (data) => HttpRequest('post', '/reset/password', data, null);

export const verifyPassword = (data) => HttpRequest('post', '/reset/password/verify_code', data, null);

export const confirmResetPassword = (data) => HttpRequest('post', '/reset/password/confirm', data, null);

export const getNotifications = () => HttpRequest('get', `/notifications/`, null, formatHeader());

export const notificationsReadAll = () => HttpRequest('get', `/notifications/read/`, null, formatHeader());

export const notificationsRead = (data) => HttpRequest('post', '/notifications/read/', data, formatHeader());

export const getNotificationsCount = () => HttpRequest('get', `/notifications/notification-count/`, null, formatHeader());
